import Config from './config';

class Aws {
  static get cognito() {
    let userPoolId;
    let client_id;
    let domain;
    let redirect_uri;

    if (Config.config.env==='prod') {
      // TFA 本番環境
      userPoolId    = 'ap-northeast-1_BrAxLehqU';
      client_id     = '359vn4r3ciioo5ceekdjkhs6te';
      domain        = 'tfa-user-management-system.auth.ap-northeast-1.amazoncognito.com';
      redirect_uri  = 'https://tfa.training.tiifa.jp';
    } else if (Config.config.env==='prod_demo') {
      // TFA デモ環境
      userPoolId    = 'ap-northeast-1_jI6Km5pyG';
      client_id     = '7ssmoms78jc9c97jge117mlnk0';
      domain        = 'tfa-lab-user-management-system.auth.ap-northeast-1.amazoncognito.com'; 
      redirect_uri  = 'https://demo-tfa-training.tiifa.jp';
    } else {
      // NC
      userPoolId    = 'ap-northeast-1_aUEklmeZJ';
      client_id     = '4ao5lj2berg1ckhq9rjs3m9f2n';
      domain        = 'test-20200603.auth.ap-northeast-1.amazoncognito.com';
      redirect_uri  = 'https://demo.training.tfa.netcompass.co.jp';
    }

    return ({
      Auth: {
        userPoolId: userPoolId,
        userPoolWebClientId: client_id,
        authenticationFlowType: 'USER_PASSWORD_AUTH', // USER_SRP_AUTH' | 'USER_PASSWORD_AUTH' | 'CUSTOM_AUTH',    
      },
      oauth: {
        domain: domain,
        redirectSignIn: redirect_uri,
        responseType: 'code'
      }
    });
  }
}

export default Aws;
