import React, { Component } from 'react';
import { Button } from 'react-bootstrap';

class QMoveButton extends Component {
  constructor(props){
    super(props);
    this.state = {
      move: (this.props.move === 'forward') ? true : false,
    }
  }

  onClick(move, end, msg, e) {
    this.props.onClick(move, end, msg);
  }

  unans() {
    let unans = [];
    this.props.answers && this.props.answers.map((ans,i)=>{
      if (ans===undefined) {
        unans.push(i);
      }
      return true;
    });
    return unans;
  }

  isLast() {
    if (this.props.index === this.props.end) {
      return true;
    } else {
      let unans = this.unans();
      if (unans.length === 0 || (unans.length === 1 && this.props.index === unans[0])) {
        return true;
      } else {
        return false;
      }
    }
  }

  renderIcon(rot) {
    return(
      <svg className={"move_direction "+rot} viewBox="0 0 34.171 40.191">
        <path className="move_direction_path" d="M 6.080078125 0.59716796875 C 3.4208984375 -1.03717041015625 0 0.87579345703125 0 3.9967041015625 L 0 20.09564208984375 L 0 36.19464111328125 C 0 39.31549072265625 3.4208984375 41.228515625 6.080078125 39.5941162109375 L 32.27001953125 23.4952392578125 C 34.8046875 21.93731689453125 34.8046875 18.2540283203125 32.27001953125 16.69610595703125 L 6.080078125 0.59716796875 Z">
        </path>
      </svg>
    );
  }

  render() {
    let display = '';
    let disabled = false;
    let rot = '';
    let msg = false;
    let end = this.isLast();
    if (this.state.move) {
      if (end) {
        display = '終了';
        let unans = this.unans().map((a)=>{return(`第${a+1}問`);});
        if (unans.length > 0) {
          msg = `${unans.join(',')} が解答されていません。`
        }
      } else {
        display = '次の問題';
      }
    } else {
      rot = 'rot'
      display = '前の問題';
      if (this.props.index === 0){
        disabled = true;
      }
    }
    if (this.props.disable) {
      disabled = true;
    }
    return (
      <Button className="btn_move" onClick={this.onClick.bind(this,this.state.move,end,msg)} disabled={disabled}><span>{display}</span>{this.renderIcon(rot)}</Button>
    );
  }
}

export default QMoveButton;
