import React, { Component } from 'react';
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';

import Function from './function'

class QAns extends Component {
  renderToggleButton() {
    let toggles = this.props.data.choices.map((c,i)=>{return( <ToggleButton className="btn_choice"
                                                                           key={i}
                                                                           type="radio"
                                                                           name="radio"
                                                                           value={c}>
                                                                {i+1}. <div className="q_format" dangerouslySetInnerHTML={Function.qFormat(c)} />
                                                              </ToggleButton>);});
    if (toggles % 2 === 1){
      toggles.push(<div className="btn_choice"></div>);
    }
    return (
      <ToggleButtonGroup className="choices"
                         name="input"
                         onChange={this.props.onClick}
                         value={this.props.ans}>
                         {toggles}
                         </ToggleButtonGroup>
    );
  }

  render() {
    return (
      <div className="question_set">
        { this.props.data.instruction && <span className="instruction">{this.props.data.instruction}</span> }
        <div className="question_">
          { this.props.data.question && this.props.data.question.map((q,i)=>{return(<span className="sentence" key={i}><div className="q_format" dangerouslySetInnerHTML={Function.qFormat(q)} /></span>);}) }
        </div>
        { this.props.data.choices && this.renderToggleButton() }
      </div>
    );
  }
}

export default QAns;
