import React, { Component } from 'react';
import { Button, Dropdown, Modal } from 'react-bootstrap';

import Function from './function';
import Preview from './Preview';
import UserInfo from './UserInfo';

class Training extends Component {
  constructor(props){
    super(props);

    let tags = [];
    this.tagtitles = this.props.entries.map(x=>{
      if (!tags.includes(x.tag)) {
        tags.push(x.tag);
        return({
          tag: x.tag,
          title: x.tagtitle.title,
          category: x.tagtitle.category,
        });
      } else {
        return null;
      }
    }).filter(x=>x!==null);

    this.state = {
      modal: false
    }
    this.handleChangeCategory = this.handleChangeCategory.bind(this);
    this.handleChangeTag = this.handleChangeTag.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.setState({ modal: true });
  }
  closeModal() {
    this.setState({ modal: false,
                    level: undefined,
                    testset: undefined });
  }

  handleChangeCategory(val) {
    const tags = this.tagtitles.filter(x=>x.category===val);
    this.setState({
      category: val,
      tags: tags,
      tag: (tags.filter(x=>x.tag===this.state.tag).length>0) ? this.state.tag : undefined,
      testset: undefined,
    })
  }
  handleChangeTag(val) {
    this.setState({
      tag: val,
      testset: undefined,
    })
  }
  handleChangeLevel(val) {
    const testsets = this.props.entries.filter(x=>x.tag===this.state.tag&&x.level===val);
    this.setState({
      level: val,
      testset: undefined,
      testset_choices: testsets,
      modal: true
    })
  }
  handleChangeSelectedTest(val) {
    this.setState({
      testset: val
    })
  }

  renderLevels() {
    const levels = Array.from(new Set(this.props.entries.filter(x=>x.tag===this.state.tag).map(x=>x.level)));
    return(
      <div className="btn_group btn_group_level">
        {levels.map((x,i)=>{return(
          <Button className={this.state.level===x ? "btn_choice btn_level selected" : "btn_choice btn_level"}
                  key={i}
                  onClick={this.handleChangeLevel.bind(this,x)}>
            <small>レベル</small>
            <span>{x}</span>
          </Button>)})}
      </div>
    );
  }
  renderTestsetChoices() {
    return(
      <div className="btn_group btn_group_testset">
        {this.state.testset_choices.map((x,i)=>{
          return(
            <Button className={this.state.testset===x ? "btn_choice btn_testset selected" : "btn_choice btn_testset"}
                    key={i}
                    onClick={this.handleChangeSelectedTest.bind(this,x)}>
              <small>第</small>
              <span>{x.testNum}</span>
              <small>回</small>
            </Button>)})}
      </div>
    );
  }
  renderPreview() {
    return(
      <div className="preview">
        <div className="meta">
          <div className="test">
            <div className={`tag tag_${this.state.testset.tag}`}>
              <span>{this.state.testset.tag} {this.state.testset.tagtitle.title}</span>
            </div>
            <div className="level">
              <span>レベル{this.state.testset.level} 第{this.state.testset.testNum}回</span>
            </div>
          </div>
          <div className="info">
            <span>全 {this.state.testset.QIDs.length} 問 目標 {Function.msToTimeJa(this.state.testset.target_time)}</span>
          </div>
        </div>
        <Preview data={this.state.testset.sample_1} />
       <Button className="btn_start" onClick={this.props.start}>START!!</Button>
      </div>
    );
  }

  render() {
    return (
      <>
        <div className="training">
          <UserInfo user={this.props.user}/>

          <div className="selection">
            <div className="steps">
              <div className="tag">
                <div className="guidance">
                  <h2>STEP 1</h2>
                  <span>分野を選びましょう。</span>
                </div>
                <div className="dropdown_area">
                  <Dropdown>
                    <Dropdown.Toggle className={`toggle category_${this.state.category}`} variant="light" id="dropdown-basic">
                      {this.state.category===undefined ? "大分野" : `${this.props.categories.filter(x=>x.category===this.state.category)[0].title}` }
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="menu">
                      {this.props.categories.map((x,i)=>{return(<Dropdown.Item className={`item category_${x.category}`} key={i} onClick={(e)=>this.handleChangeCategory(x.category)}>{x.title}</Dropdown.Item>)})}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>

                <div className="dropdown_area">
                    <Dropdown>
                      <Dropdown.Toggle className={`toggle tag_${this.state.tag}`} variant="light" id="dropdown-basic">
                        {this.state.tag===undefined ? "小分野" : `${this.state.tag} ${this.tagtitles.filter(x=>x.tag===this.state.tag)[0].title}` }
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="menu">
                        {this.state.tags!==undefined && 
                          this.state.tags.map((x,i)=>{return(<Dropdown.Item className={`item tag_${x.tag}`} key={i} onClick={(e)=>this.handleChangeTag(x.tag)}>{x.tag} {x.title}</Dropdown.Item>)})}
                      </Dropdown.Menu>
                    </Dropdown>
                </div>
              </div>

              <div className={"level"+(this.state.tag===undefined?" transparent":"")}>
                { this.state.tag!==undefined &&
                  <>
                    <div className="guidance">
                      <h2>STEP 2</h2>
                      <span>レベルを選びましょう。</span>
                    </div>
                    { this.renderLevels() }
                  </>
                }
              </div>
            </div>

          </div>
        </div>

        <Modal show={this.state.modal}
                onHide={this.closeModal}
                dialogClassName="modal-90w modal_select_test"
                aria-labelledby="contained-modal-title-vcenter"
                centered >
          <Modal.Header closeButton>
            <div className="guidance">
              <h2>STEP 3</h2>
              <span>テストを選びましょう。</span>
            </div>
          </Modal.Header>
          <Modal.Body>
            {this.state.testset_choices && 
              <>
                <div className="testset">
                  { this.renderTestsetChoices() }
                </div>
                { this.state.testset && this.renderPreview()}
              </>
            }
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default Training;
