import React, { Component } from 'react';

import Function from './function'

class Preview extends Component {
  render() {
    return (
      <div className="question_set">
        { this.props.data.instruction && <span className="instruction">{this.props.data.instruction}</span> }
        <div className="question_ q_format">
          { this.props.data.question && this.props.data.question.map((q,i)=>{return(<span key={i}><div dangerouslySetInnerHTML={Function.qFormat(q)} /></span>);}) }
        </div>
        { this.props.data.choices && 
          <div className="choices q_format">{ this.props.data.choices.map((c,i)=>{return(<div key={i}>{i+1}. <span dangerouslySetInnerHTML={Function.qFormat(c)} /></div>);}) }</div>
        }
      </div>
    );
  }
}

export default Preview;
