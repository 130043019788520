import React, { Component } from 'react';

import QListButton from './QListButton'

class QList extends Component{
  constructor(props){
    super(props);
    this.unit = 10;
    this.onClick = this.onClick.bind(this);
  }

  onClick(index, e) {
    this.props.onClick(index);
  }

  renderButtons() {
    let buttons = this.props.data.map((item, i) =>
      <div key={i} className="btn_qlist_wrapper">
        <QListButton index={i}
                     confidence={this.props.confidence[i]}
                     onClick={(index)=>this.onClick(index)}
                     disable={this.props.disable}/>
      </div>
    );
    return buttons;
  }

  renderSpace() {
    let fraction = this.props.data.length % this.unit;
    if (fraction !== 0) {
      let spacer = [...Array(this.unit - fraction)].map((_, i) => 
        <div key={i} className="spacer"></div>
      );
      return spacer;
    }
  }

  render() {
    return (
      <div className="qlist">
        {this.renderButtons()}
        {this.renderSpace()}
      </div>
    );
  }
}

export default QList;
