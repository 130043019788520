import React, { Component } from 'react';

class UserInfo extends Component {
  render() {
    return (
      <div className="user">
        <div className="personal"> 
          <div className="name"><span>ID: {this.props.user.display_uid}</span><span>{this.props.user.username} さん</span></div>
          <div className="school"><small>所属: {this.props.user.affiliation}</small><span className="teacher">担任名: {this.props.user.instructor}</span></div>
        </div>
        <div className="target"><small>次の目標</small><span>{this.props.user.target}</span></div>
      </div>
    );
  }
}

export default UserInfo;
