import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';

class Table extends Component {
  constructor(props){
    super(props);
    const columns = [{
      dataField: 'num',
      text: '問題番号',
      sort: true
    }, {
      dataField: 'judge',
      text: '正誤',
      sort: true
    }, {
      dataField: 'confidence',
      text: '確信',
      sort: true
    }, {
      dataField: 'time',
      text: '時間',
      sort: true
    }];
    this.rowEvents = {
      onClick: (e, row, rowIndex) => {
        this.props.onClick(row.num-1);
      }
    }
    this.state = {
      columns: columns,
    }
  }

  render() {
    const selectRow = {
      mode: 'radio',
      clickToSelect: true,
      hideSelectColumn: true,
      hideSelectAll: true,
      style: { backgroundColor: '#a8edea' },
    };
    return(
      <BootstrapTable
        keyField='num'
        data={ this.props.data }
        columns={ this.state.columns }
        selectRow={ selectRow }
        rowEvents={ this.rowEvents }
        hover
        condensed
      />
    );
  }
}

export default Table;
