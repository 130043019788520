import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';

import Config from './config'

import QList from './QList'
import QConfidenceButtons from './QConfidenceButtons'
import QMoveButton from './QMoveButton'
import QAns from './QAns'
import CountDownTimer from './CountDownTimer'

class Question extends Component {
  constructor(props){
    super(props);
    this.q_count = this.props.test.testset.questions.length;
    this.state = {
      datetime: new Date(),
      index: 0,
      qlist: false,
      answers: new Array(this.q_count).fill(undefined),
      confidences: new Array(this.q_count).fill(undefined),
      elapsed_times: new Array(this.q_count).fill(0),
      move_disable: false,
      score: undefined,
      ansAll_msg: undefined,
      submit_modal: false,
      submittable: false,
    }
    this.setQuestion = this.setQuestion.bind(this);
    this.setList = this.setList.bind(this);
    this.onClickMove = this.onClickMove.bind(this);
    this.submitTest = this.submitTest.bind(this);

    this.setConfAndMove = this.setConfAndMove.bind(this);
    this.setAns = this.setAns.bind(this);
    this.setTime = this.setTime.bind(this);

    this.closeQListModal = this.closeQListModal.bind(this);
    this.closeSubmitModal = this.closeSubmitModal.bind(this);
  }

  closeQListModal() {
    this.setState({ datetime: Date.now(),
                    qlist: false });
  }
  closeSubmitModal() {
    this.setState({ datetime: Date.now(),
                    submit_modal: false,
                    qlist: !this.state.submittable });
  }

  submitTest(){
    let result = this.score();
    this.props.submitTest(result);
  }

  score() {
    let questions = this.props.test.testset.questions.map((q,i) => {
      return({
        qid: q.QID,
        num: i+1,
        tags: q.tags,
        correct_ans: q.answer,
        ans: this.state.answers[i],
        judge: (q.answer === this.state.answers[i]) ? 0 : 1,       // 正答0、誤答1
        confidence: this.state.confidences[i],                     // 確信あり1, 確信なし0
        time: this.state.elapsed_times[i]
      });
    });
    let judge_sum = questions.reduce((prev, res) => prev + res.judge, 0); // 誤答数
    let score = judge_sum / this.q_count;
    let data = {
      questions: questions,
      score: score,
      score_count: `${this.q_count-judge_sum}/${this.q_count}`,
    }
    return data;
  }

  setQuestion(index) {
    this.setState({datetime: Date.now(), index: index, qlist: false});
  }
  setList() {
    if (!this.state.move_disable) {
      let [now, elapsed_times] = this.setTime();
      this.setState({ datetime:now,
                      elapsed_times: elapsed_times,
                      qlist: true});
    }
  }
  onClickMove(forward, end, msg) {
    let [now, elapsed_times] = this.setTime();
    // submit
    if (forward && end){
      // Production で未解答問題がある場合、未解答問題を提示
      if (Config.config.production && msg) {
        this.setState({ datetime: now,
                        elapsed_times: elapsed_times,
                        ansAll_msg: msg,
                        submit_modal: true });
      } else {
        this.setState({ datetime: now,
                        elapsed_times: elapsed_times,
                        submit_modal: true,
                        submittable: true });
      }
    }
    else{
      let next_index = forward ? this.state.index + 1 : this.state.index - 1;
      this.setState({ datetime: now,
                      elapsed_times: elapsed_times,
                      index: next_index,
                      qlist: false });
    }
  }

  setConfAndMove(value) {
    let conf = [...this.state.confidences];
    conf[this.state.index] = value;
    this.setState({ confidences: conf,
                    move_disable: false });
    if (this.state.answers[this.state.index] !== undefined && this.state.index !== this.q_count-1) {
      this.onClickMove(true);
    }
  }
  setAns(value) {
    let ans = [...this.state.answers];
    ans[this.state.index] = value;
    let conf = [...this.state.confidences];
    conf[this.state.index] = undefined;
    this.setState({ answers: ans,
                    confidences: conf,
                    move_disable: true});
  }
  setTime() {
    let now = Date.now();
    let elapsed_times = [...this.state.elapsed_times];
    elapsed_times[this.state.index] += now - this.state.datetime;
    return [now, elapsed_times]
  }

  render() {
    return (
      <>
        <div className="question">
          <div className="bookmark_wrapper">
            <div className="bookmark">
          		<svg className="bookmark_r" viewBox="839 76 509.58 119.944">
          			<path d="M 1348.579833984375 195.9435882568359 L 1348.579833984375 195.9425964355469 L 908.4645385742188 195.9425964355469 L 838.999755859375 132.5079040527344 L 908.4645385742188 75.99958801269531 L 1348.579833984375 75.99958801269531 L 1348.579833984375 195.9425964355469 L 1348.579833984375 195.9435882568359 Z M 894.8367309570312 114.4997787475586 C 883.2574462890625 114.4997787475586 873.8370971679688 123.9205932617188 873.8370971679688 135.5003967285156 C 873.8370971679688 147.0796508789062 883.2574462890625 156.5000915527344 894.8367309570312 156.5000915527344 C 906.4159545898438 156.5000915527344 915.83642578125 147.0796508789062 915.83642578125 135.5003967285156 C 915.83642578125 123.9205932617188 906.4159545898438 114.4997787475586 894.8367309570312 114.4997787475586 Z">
          			</path>
          		</svg>
              <svg className="bookmark_l" viewBox="4082.699 164.714 97.543 61.152">
                <path d="M 4090.90771484375 164.7143402099609 L 4180.2421875 182.2254943847656 L 4180.2421875 201.2415161132812 L 4089.676513671875 225.8665924072266 L 4092.54931640625 215.3325347900391 L 4082.699462890625 208.9026489257812 L 4139.61083984375 193.3067779541016 L 4082.699462890625 182.2254943847656 L 4093.78076171875 174.9747772216797 L 4090.90771484375 164.7143402099609 Z">
                </path>
              </svg>
              <div className="qnum">
                <h2>第{this.state.index+1}問/<span className="total_count">{this.q_count}問</span></h2>
              </div>
            </div>
        	</div>

          <div className="meta">
            <div className={`tag tag_${this.props.test.testset.tag}`}><span>{this.props.test.tag}</span></div>
            <div className="level"><span>レベル{this.props.test.level} 第{this.props.test.testset.testNum}回</span></div>
          </div>

          <div className="question_inner">
            <div className="question_inner_inner">
              { !this.state.qlist &&
                <div className="qlist_mini" onClick={this.setList}>
                  <QList data={this.props.test.testset.questions} confidence={this.state.confidences} disable={true}/>
                </div>
              }

              { !this.state.qlist &&
                <QAns data={this.props.test.testset.questions[this.state.index]}
                      ans={this.state.answers[this.state.index]}
                      onClick={this.setAns} />
              }
            </div>
            <div className="button_group">
              <QMoveButton move="back"
                           index={this.state.index}
                           disable={this.state.move_disable}
                           onClick={this.onClickMove} />
              <QConfidenceButtons disable={(this.state.answers[this.state.index]===undefined||this.state.qlist)?true:false}
                                  confidence={this.state.confidences[this.state.index]}
                                  onClick={this.setConfAndMove}/>
              <QMoveButton move="forward"
                           index={this.state.index}
                           end={this.q_count-1}
                           answers={this.state.answers}
                           disable={this.state.move_disable}
                           onClick={this.onClickMove} />
            </div>
            <CountDownTimer add_class="timer_total"
                            limit={this.props.test.testset.limit_time}
                            elapsed={this.state.elapsed_times.reduce((prev, next) => prev + next, 0)}
                            warn_lessthan={300000}
                            run={!this.state.qlist&&!this.state.submit_modal}/>

          </div>
        </div>

        <Modal show={this.state.qlist}
               onHide={this.closeQListModal}
               dialogClassName="qlist_modal"
               centered >
          <QList data={this.props.test.testset.questions}
                 confidence={this.state.confidences}
                 onClick={this.setQuestion}
                 disable={false}/>
        </Modal>

        <Modal show={this.state.submit_modal}
               onHide={this.closeSubmitModal}
               dialogClassName="submit_modal"
               centered >
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body>
            {this.state.submittable ? "解答を終了します。よろしいですか？" : this.state.ansAll_msg}
          </Modal.Body>
          <Modal.Footer>
           {this.state.submittable ? 
              <>
                <Button variant="outline-secondary" onClick={this.closeSubmitModal}>いいえ</Button>
                <Button variant="secondary" onClick={this.submitTest}>はい</Button>
              </>
              :
              <>
                <Button variant="outline-secondary" onClick={this.closeSubmitModal}>もどる</Button>
              </>
            }
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default Question;
