import React, { Component } from 'react';
import { Button } from 'react-bootstrap';

import Function from './function';
import Table from './Table';
import Review from './Review';
import UserInfo from './UserInfo';

class Result extends Component {
  constructor(props){
    super(props);
    this.state = {
      index: null,
      window_close:false
    }
    this.setReview = this.setReview.bind(this);
  }

  end(e){
    window.close();
    this.setState({window_close: true});
  }

  setReview(index, e){
    this.setState({
      index: index
    })
  }

  render() {
    return (
      this.state.window_close ? (
        <div className="logout_msg">ブラウザを閉じて終了してください</div>
      ) : (
        <div className="result">
          <div className="meta">
            <div className={`tag tag_${this.props.test.testset.tag}`}><span>{this.props.test.tag}</span></div>
            <div className="level"><span>レベル{this.props.test.level} 第{this.props.test.testset.testNum}回</span></div>
          </div>

          <UserInfo user={this.props.user}/>

          <div className="summary">
            <div className="summary_inner">
              <div className="header">
                <h3>RESULT</h3>
                <div className="score"><span>SCORE: {Math.round((1-this.props.result.score)*100)} [{this.props.result.score_count}]</span></div>
              </div>
              <div className="table_wrapper">
                <Table data={Function.tableData(this.props.result.questions)} onClick={this.setReview}/>
              </div>
              <div className="btn_group">
                <div className="btn_group_back">
                  <Button className="btn_ btn_retry" onClick={this.props.retry}><span>もう一度</span></Button>
                  <Button className="btn_ btn_select" onClick={this.props.select}><span>選択画面へ</span></Button>
                </div>
                <Button className="btn_ btn_end" onClick={this.end.bind(this)}><span>アプリ終了</span></Button>
              </div>
            </div>
            <div className="review">
              {this.state.index===null ? <Review/>
                                      : <Review num={this.state.index+1}
                                                question={this.props.test.testset.questions[this.state.index]}
                                                answer={this.props.result.questions[this.state.index].ans} />}
            </div>
          </div>
        </div>
      )
    );
  }
}

export default Result;
