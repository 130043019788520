import React, { Component } from 'react';
import axios from 'axios';

import Config from './config'
import Function from './function'

import Header from './Header'
import Training from './Training'
import Question from './Question'
import Result from './Result'

class Session extends Component {
  constructor(props){
    super(props);
    this.getSelected = React.createRef();
    this.getResult   = React.createRef();
    this.state = {
      sessionID: undefined,
      test: undefined,
      result: undefined,
    }
    this.getTest = this.getTest.bind(this);
    this.submitTest = this.submitTest.bind(this);
    this.retry = this.retry.bind(this);
    this.select = this.select.bind(this);
  }

  retry() {
    this.setState({ sessionID: Function.createSessionId(this.props.user.uid, this.state.test.testset.textID, Date.now()),
                    result: undefined })
  }
  select() {
    this.setState({ sessionID: undefined,
                    test: undefined,
                    result: undefined })
  }

  getTest() {
    let url = Config.config.serverUrl + '/session';
    let testset = this.getSelected.current.state.testset;
    axios
      .post(url,
            { uid: this.props.user.uid,
              test: this.props.test,
              textID: testset.textID },
            { withCredentials: true })
      .then((res) => {
        const response = res.data;
        this.setState({ sessionID: Function.createSessionId(this.props.user.uid, response.testset.textID, Date.now()),
                        test: { tag: `${testset.tag} ${testset.tagtitle.title}`,
                                level: testset.level,
                                type: response.test_type,
                                testset: response.testset }});
      })
      .catch((err) => {
        console.log(err);
      });
  }
  submitTest(result){
    let url = Config.config.serverUrl + '/session/end';
    axios
      .post(url,
            { uid: this.props.user.uid,
              test: this.props.test,
              sessionID: this.state.sessionID,
              textID: this.state.test.testset.textID,
              result: result },
            { withCredentials: true })
      .then(() => {
        this.setState({ result: result });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  renderWithStatus() {
    if (this.props.test==='training') {
      if (this.state.test===undefined) {
        return (
          <Training user={this.props.user}
                    categories={this.props.categories}
                    entries={this.props.entries}
                    start={this.getTest}
                    ref={this.getSelected} />
        );
      } else if (this.state.result===undefined){
        return (
          <Question test={this.state.test}
                    submitTest={this.submitTest}
                    ref={this.getResult} />
        );
      } else {
        return (
          <Result user={this.props.user}
                  test={this.state.test}
                  result={this.state.result}
                  select={this.select}
                  retry={this.retry} />
        );
      }
    }
  }

  render() {
    return (
      <div className="wrapper">
        <Header />
        <main>{this.renderWithStatus()}</main>
      </div>
    );
  }
}

export default Session;
