class Function {
  static dtToDateStr(dt) {
    // dt: GMT, getMonth(), getDate() は localtime を返す
    let dt_ = new Date(dt);
    return [dt_.getMonth()+1, dt_.getDate()].join('/')
  }

  static msToTime(ms) {
    let sign = '';
    if (ms < 0) {
      ms *= -1;
      sign = '-';
    }
    let tenmilisec = Math.floor((ms % 1000) / 10);
    let time = Math.floor(ms/1000);
    let sec = time % 60;
    let min = Math.floor(time/60);
    return `${sign}${min}:${('0'+sec).slice(-2)}:${('0'+tenmilisec).slice(-2)}`
  }

  static msToTimeJa(ms) {
    let time = Math.floor(ms/1000);
    let sec = time % 60;
    let min = Math.floor(time/60);
    return `${min}分${('0'+sec).slice(-2)}秒`
  }

  static qFormat(str) {
    // カッコ
    str = str.replace(/\(\s*\)/g, '(<span class="brackets"></span>)');
    // 番号付き下線
    str = str.replace(/(\d)_([^_]*)_/g, '<span class="num">$1.</span><span class="underline">$2</span>');
    // 番号なし下線
    str = str.replace(/_([^_]*)_/g, '<span class="underline">$1</span>');
    // 穴埋め下線
    str = str.replace(/\[\]/g, '<span class="fillin"></span>');
    return {__html: str};
  }

  static tableData(data) {
    return data.map((q) => {
      return({
        num: q.num,
        judge: (q.judge===0) ? '〇' : '×',
        confidence: (q.confidence===1) ? 'あり' : 'なし',
        time: this.msToTime(q.time)
      });
    });
  }

  static createSessionId(uid, textID, ts) {
    // dt: GMT, getMonth(), getDate() は localtime を返す
    let dt = new Date(ts);
    let y    = dt.getFullYear();
    let m    = `00${dt.getMonth() + 1}`.slice(-2);
    let d    = `00${dt.getDate()}`.slice(-2);
    let hour = `00${dt.getHours()}`.slice(-2);
    let min  = `00${dt.getMinutes()}`.slice(-2);
    let sec  = `00${dt.getSeconds()}`.slice(-2);
    let ms   =  `000${dt.getMilliseconds()}`.slice(-3);
  
    return `${uid}_${textID}_${y}-${m}-${d}-${hour}-${min}-${sec}.${ms}`;
  }
}

export default Function;
