import React, { Component } from 'react';

import Function from './function'

class Review extends Component {
  render() {
    return (
      (this.props.question===undefined&&this.props.answer===undefined) ? (
        <div className="review_inner">
          <span>表をクリックすると、出題された問題を確認できます。</span>
        </div>
      ) : (
        <div className="review_inner">
          <div>
            <div className="num">第{this.props.num}問</div>
            <div>{ this.props.question && this.props.question.instruction && this.props.question.instruction.map((q,i)=>{return(<span key={i} className="instruction">{this.props.question.instruction}</span>);}) }</div>
            <div className="question_ q_format">
              { this.props.question && this.props.question.question　&& this.props.question.question.map((q,i)=>{return(<span key={i}><div dangerouslySetInnerHTML={Function.qFormat(q)} /></span>);}) }
            </div>
          </div>
          <div>{ this.props.question && this.props.question.choices.map((c,i)=>{return(<div key={i}　className="choices q_format">{i+1}. <span dangerouslySetInnerHTML={Function.qFormat(c)} /></div>);}) }</div>
          <div><span>あなたの解答: </span>{ this.props.answer && <div className="answer q_format" dangerouslySetInnerHTML={Function.qFormat(this.props.answer)} /> }</div>
          <div><span>正解: </span>{ this.props.question && this.props.question.answer && <div className="answer q_format" dangerouslySetInnerHTML={Function.qFormat(this.props.question.answer)} /> }
                                                          { this.props.question && this.props.question.answer_revise && <span> → {this.props.question.answer_revise}</span>}</div>
        </div>
      )
    );
  }
}

export default Review;
